<template>
  <div class="container-login">
    <a-button class="btn-back" @click="back()">
      <div class="d-flex">
        <img src="@/assets/images/arrowBack.svg" class="mr-1 iconlang" alt="" />
        {{ lbl['btn_back'] }}
      </div></a-button
    >
    <div class="box-login text-center">
      <!-- language -->
      <div class="language">
        <a-select v-model="currentLocale" class="select-language">
          <a-select-option value="en" @click="switchLang('en')">
            <img src="@/assets/images/en.svg" class="mr-2 iconlang" alt="" />ENG
          </a-select-option>
          <a-select-option value="th" @click="switchLang('th')">
            <img src="@/assets/images/th.svg" class="mr-2 iconlang" alt="" />TH
          </a-select-option>
          <a-select-option value="ph" @click="switchLang('ph')">
            <img src="@/assets/images/ph.svg" class="mr-2 iconlang" alt="" />PH
          </a-select-option>
        </a-select>
      </div>
      <div class="mb-3 mt-3 d-flex flex-column">
        <label class="text-h-forgot">{{
          lbl['reset-password-page-title']
        }}</label>
        <label class="text-c-forgot">{{
          lbl['reset-password-subheader']
        }}</label>
      </div>
      <div v-if="isSuccess" class="message-success w-100 mb-2">
        <a-alert type="success">
          <span slot="description" class="d-flex justify-content-center py-2">
            <span class="lbl-success">{{
              lbl['reset-password-complete-message-1']
            }}</span>
          </span>
        </a-alert>
      </div>
      <div v-else>
        <a-form-item class="text-left">
          <div class="text-email">
            {{ lbl['reset-password-new-password-placeholder'] }}
          </div>
          <a-input-password
            v-model="password"
            :placeholder="lbl['reset-password-new-password-placeholder']"
          >
          </a-input-password>
        </a-form-item>
        <a-form-item class="text-left">
          <div class="text-email">
            {{ lbl['register-confirm-password-placeholder'] }}
          </div>
          <a-input-password
            v-model="cfpassword"
            :placeholder="lbl['register-confirm-password-placeholder']"
          >
          </a-input-password>
        </a-form-item>
        <a-button
          class="primarybtn mt-3"
          :disabled="!passwordState"
          block
          @click="resetpassword()"
        >
          {{ lbl['reset-password-button'] }}
        </a-button>
        <div class="d-flex mb-3 mt-5">
          <a-button
            type="link"
            class="outlineprimary noborder"
            block
            @click="goto('Login')"
          >
            {{ lbl['reset-password-login'] }}
          </a-button>
          <a-button
            type="link"
            class="outlineprimary noborder"
            block
            @click="goto('RegisterVue')"
          >
            {{ lbl['reset-password-signup'] }}
          </a-button>
        </div>
      </div>
      <div v-if="isSuccess">
        <a-button class="btn_login" block @click="goLogin()">
          {{ lbl['forgot-password-complete-login'] }}
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import Vue from 'vue'
import Locale from '@/helper/locale.js'
import Cache from '@/helper/Cache.js'
import BzbsProfile from '@/core/Account/service/BzbsProfile'

export default {
  name: 'ForgotPassword',
  components: {},
  mixins: [Mixin],
  data() {
    return {
      usercache: Cache.get('CRMUser'),
      currentLocale: 'en',
      email: '',
      password: '',
      cfpassword: '',
      refcode: '',
      isSuccess: false,
    }
  },
  computed: {
    passwordState() {
      if (this.password == null) return false
      return this.password.length >= 6 && this.password == this.cfpassword
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.handleFooter(true)
      this.isSuccess = false
      this.currentLocale = Locale.getLocaleShort()
      this.refcode = this.$route.query.refcode
      //Bug-384752
      if (this.$route.query.email) this.email = this.$route.query.email
      else this.email = this.$route.query.Id
    },
    goLogin() {
      this.$router.push('/Account/Login')
    },
    config() {
      return Vue.bzbsConfig
    },
    async switchLang(code) {
      this.handleLoading(true)
      this.updateLanguage(code)
      await this.setLang(code)
      this.$router.go()
    },
    updateLanguage(code) {
      var locale
      switch (code) {
        case 'th':
          locale = 1054
          break
        case 'en':
          locale = 1033

          break
        case 'ph':
          locale = 13321

          break
        default:
          locale = 1033
      }
      return new Promise(resolve => {
        BzbsProfile.updateLanguage(locale)
          .then(res => {
            var CRMUserCookie = Cache.get('CRMUser')
            CRMUserCookie.Token = res.data.data
            Cache.set('CRMUser', CRMUserCookie)
            resolve(res.data)
          })
          .catch(err => {
            console.log(err)
            resolve(err)
          })
      })
    },
    resetpassword() {
      this.handleLoading(true)
      const data = {
        refcode: this.refcode,
        email: this.email,
        change: this.password,
      }
      BzbsProfile.postresetPassword(null, data)
        .then(() => {
          this.handleLoading(false)
          this.isSuccess = true
        })
        .catch(err => {
          this.handleLoading(false)
          console.log(err)
        })
    },
    closeError() {
      this.isAPIError = false
    },
    back() {
      this.$router.push('/Account/Login')
    },
  },
}
</script>

<style lang="scss">
@import '@/style/main.scss';

.box-login {
  position: absolute;
  top: 45%;
  left: 50%;
  border-radius: 12px;
  background-color: $color-white;
  padding: 30px;
  transform: translate(-50%, -50%);
  box-shadow: 0 16px 48px 0 $color-black-2b;
  width: 445px;
  .title-login {
    font-size: 48px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: $black;
  }

  .ant-input {
    height: 40px;
    font-size: 15px;
  }

  .anticon svg {
    color: $color-black-32;
  }

  .btn_login,
  .btn-forgot-password {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 16px 0;
    padding: 10px;
    background-color: $primary;
    border: initial;
    font-size: 16px;
    font-weight: bold;
    color: $color-white;
  }
  .text-h-forgot {
    font-size: 20px;
    font-weight: 500;
    color: $text-dark;
  }
  .text-c-forgot {
    font-size: 14px;
    color: $color-grey-75;
  }
  .forgotpw {
    color: $color-blue-f3;
    text-decoration: underline;
  }
  .language {
    text-align: right;
  }
  .text-email {
    line-height: 24px;
    font-size: 14px;
    font-weight: bold;
    color: $color-grey-42;
  }
  .alert-error-api-container {
    display: flex !important;
    justify-content: space-between;
  }
  .lbl-error {
    font-size: 14px;
    line-height: 1.43;
    color: $color-red-52;
    padding-left: 5px;
  }
  .lbl-success {
    font-size: 14px;
    line-height: 1.43;
    color: $primary-dark;
  }
  .ant-alert-with-description.ant-alert-no-icon {
    padding: 5px;
  }
  .mt-30 {
    margin-top: 30px;
  }
}
.iconlang {
  width: 18px;
  height: 18px;
}
.btn-back {
  font-size: 16px;
  font-weight: bold;
  color: $color-grey-75 !important;
  background-color: $color-white !important;
  margin: 60px 0px 0px 100px;
}

@media only screen and (max-device-width: 600px) and (orientation: portrait) {
  .box-login {
    width: 80%;
  }

  .btn-back {
    margin: 60px 10px 0px 40px;
  }
}

@media only screen and (max-device-width: 600px) and (orientation: landscape) {
  .box-login {
    width: 80%;
  }
}
</style>
